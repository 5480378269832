import { FC } from "react";
import {
  Stack,
  Box,
  css,
  SystemProps,
  Button,
  Icon,
} from "@storyofams/react-ui";
import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
  NODE_LI,
  NODE_HR,
  NODE_IMAGE,
  NODE_QUOTE,
} from "storyblok-rich-text-react-renderer";

import { Divider } from "../Divider";
import { Heading } from "../Heading";
import { NavUnderline } from "../Icons";
import { Text } from "../Text";

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
  isCentered?: boolean;
  lineColor?: string;
  bgColor?: string;
  isQuoteBg?: boolean;
}

export const RichTextNH: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  isCentered,
  bgColor,
  lineColor,
  isQuoteBg,
  ...props
}) => (
  <Box
    css={css({
      "> *:last-child": { pb: 0 },
      "blockquote > *:last-child": { pb: 0 },
    })}
    {...props}
  >
    {render(text, {
      markResolvers: {
        [MARK_BOLD]: (children) => <b>{children}</b>,
        [MARK_LINK]: (children, props) => {
          const { href } = props;
          let modifyHref = href;
          if (props.linktype === "email") {
            modifyHref = "mailto:".concat(href);
          }
          if (props.anchor) {
            modifyHref = modifyHref.concat(`#${props.anchor}`);
          }
          return (
            <Button
              display="inline"
              cursor="pointer"
              css={css({
                ".underline": {
                  position: "absolute",
                  right: "-4px",
                  height: 12,
                  bottom: 0,
                  width: "calc(100% + 8px)",
                  transition: "width 0.32s ease-in-out",
                  zIndex: -1,
                  svg: {
                    width: "100%",
                    height: "100%",
                    color: "sand",
                  },
                },
                "&&:hover": {
                  ".underline": {
                    width: "0",
                  },
                },
              })}
            >
              <a
                href={modifyHref}
                target={props.target}
                rel="noopener noreferrer"
              >
                {children}
              </a>
              <Icon className="underline" icon={NavUnderline} width="100%" />
            </Button>
          );
        },
        ...markResolvers,
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => (
          <Heading
            as={`h${level}` as any}
            variant={`h${level}` as any}
            pb={["16px", "20px"]}
            fontWeight={600}
            fontFamily="Barlow"
            width={isCentered ? "max-content" : "auto"}
            mx={isCentered ? "auto" : 0}
          >
            {children}
          </Heading>
        ),
        [NODE_PARAGRAPH]: (children) => (
          <Text
            as="p"
            width={isCentered ? "max-content" : "auto"}
            mx={isCentered ? "auto" : 0}
            fontSize={["16px", "18px"]}
            fontWeight={400}
            lineHeight={"27px"}
            pb={["12px", "16px"]}
          >
            {children}
          </Text>
        ),
        [NODE_OL]: (children) => (
          <Stack
            space={[1, 2]}
            // @ts-ignore
            as="ol"
            pl={2}
            width={isCentered ? "max-content" : "auto"}
            mx={isCentered ? "auto" : 0}
            pb={[2, 3]}
            flexDirection="column"
            css={css({
              "margin-left": "1rem",
              "> li::marker": {
                "font-weight": "bold",
              },
            })}
          >
            {children}
          </Stack>
        ),
        [NODE_UL]: (children) => (
          <Stack
            space={[1, 2]}
            // @ts-ignore
            as="ul"
            width={isCentered ? "max-content" : "auto"}
            mx={isCentered ? "auto" : 0}
            pl={2}
            pb={[2, 3]}
            alignItems={props.alignContent === "center" ? "center" : "start"}
            flexDirection="column"
          >
            {children}
          </Stack>
        ),
        [NODE_LI]: (children) => (
          <Text as={"li" as any} css={css({ p: { pb: 0 } })}>
            {children}
          </Text>
        ),
        [NODE_HR]: () => <Divider mb={[2, 3]} />,
        [NODE_IMAGE]: () => {
          return null;
        },
        [NODE_QUOTE]: (children) => (
          <Text
            // py={["16px !important", "24px !important"]}
            as="blockquote"
            pl={["14px", "20px"]}
            bg={isQuoteBg ? bgColor : "#fcf9f7"}
            borderLeftColor={isQuoteBg ? lineColor : "soft"}
            borderLeftWidth="4px"
            borderLeftStyle="solid"
            fontWeight={400}
            width={isCentered ? "max-content" : "auto"}
            mx={isCentered ? "auto" : 0}
          >
            {children}
          </Text>
        ),
        ...nodeResolvers,
      },
    })}
  </Box>
);
